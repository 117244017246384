<template>
	<div>

		

	</div>
</template>
<script>
	export default {
		data() {
			return {
			}
		},
		beforeMount() {
			
		},
		methods: {
			
		}
	}
</script>