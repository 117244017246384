<template>
	<div class="border-t border-blue-200 -mt-8 pt-8 pb-20">

		<div class="mb-10">
			<h1 class="font-bold text-2xl mb-3">Upgrade Plan</h1>
			<div class="text-sm text-gray-500 font-light">To enjoy this feature and many more, upgrade to a higher package.</div>
		</div>

		<div class="flex flex-wrap mb-2">
			<div class="w-2/10">
				
				<custom-select
					class="pl-4 pr-6 py-2 border border-blue-500 text-blue-500 rounded-sm inline-block text-sm custom-select relative"
					:options="billingOptions"
					v-model="billingOption"
				/>

			</div>
			<div class="w-2/10 px-4">

				<div class="text-xs font-bold text-gray-600 mb-3">
					<span>Basic</span>
					<span
						class="badge badge-green badge-xs font-normal ml-1 -mt-3"
						style="font-size: 8px; transform: translateY(-6px) scale(.8);"
						v-if="plans.active && plans.active.plan_name == 'basic'"
					>
						Active Plan
					</span>
				</div>

				<div class="text-xl font-bold mb-4">Free</div>

				<div class="text-xs text-gray-600 font-light mb-8">
					<div class="mb-2">
						<b class="font-bold">1</b> Expense Card
					</div>
					<div class="mb-2">
						<b class="font-bold">3</b> Max Issued Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">50</b> Receipt Storage
					</div>
					<div class="mb-2">
						<b class="font-bold">No</b> Business Credit
					</div>
				</div>

				<button
					type="button"
					class="btn btn-blue-outline xl:px-10"
					@click.prevent="selectPlan(plans.data[0])"
					:disabled="plans.loading"
				>
					Continue
				</button>

			</div>
			<div class="w-2/10 px-4">

				<div class="text-xs font-bold text-blue-500 mb-3">
					<span>Premium</span>
					<span
						class="badge badge-green badge-xs font-normal ml-1 -mt-3"
						style="font-size: 8px; transform: translateY(-6px) scale(.8);"
						v-if="plans.active && plans.active.plan_name == 'premium'"
					>
						Active Plan
					</span>
				</div>

				<div class="text-xl font-bold mb-4">
					₦50k
					<small class="font-light text-xs opacity-25 mr-2">Annually</small>
				</div>

				<div class="text-xs text-gray-600 font-light mb-8">
					<div class="mb-2">
						<b class="font-bold">5</b> Free Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">50</b> Max Issued Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">2k</b> Receipt Storage
					</div>
					<div class="mb-2">
						Up to <b class="font-bold">500k</b> Business Credit
					</div>
				</div>

				<button
					type="button"
					class="btn btn-blue xl:px-10"
					@click.prevent="selectPlan(plans.data[1])"
					:disabled="plans.loading"
				>
					Get Premium
				</button>

			</div>
			<div class="w-2/10 px-4">

				<div class="text-xs font-bold text-purple-500 mb-3">
					<span>Enterprise</span>
					<span
						class="badge badge-green badge-xs font-normal ml-1 -mt-3"
						style="font-size: 8px; transform: translateY(-6px) scale(.8);"
						v-if="plans.active && plans.active.plan_name == 'enterprise'"
					>
						Active Plan
					</span>
				</div>

				<div class="text-xl font-bold mb-4">
					₦150k
					<small class="font-light text-xs opacity-25 mr-2">Annually</small>
				</div>

				<div class="text-xs text-gray-600 font-light mb-8">
					<div class="mb-2">
						<b class="font-bold">25</b> Free Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">500</b> Max Issued Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">10k</b> Receipt Storage
					</div>
					<div class="mb-2">
						Up to <b class="font-bold">2.5M</b> Business Credit
					</div>
				</div>

				<button
					type="button"
					class="btn btn-purple xl:px-10"
					@click.prevent="selectPlan(plans.data[2])"
					:disabled="plans.loading"
				>
					Get Enterprise
				</button>

			</div>
			<div class="w-2/10 px-4">

				<div class="text-xs font-bold mb-3">
					<span>Corporate</span>
					<span
						class="badge badge-green badge-xs font-normal ml-1 -mt-3"
						style="font-size: 8px; transform: translateY(-6px) scale(.8);"
						v-if="plans.active && plans.active.plan_name == 'corporate'"
					>
						Active Plan
					</span>
				</div>

				<div class="text-xl font-bold mb-4">Custom</div>

				<div class="text-xs text-gray-600 font-light mb-8">
					<div class="mb-2">
						<b class="font-bold">100</b> Free Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">Unlimited</b> Issued Cards
					</div>
					<div class="mb-2">
						<b class="font-bold">Unlimited</b> Receipt Storage
					</div>
					<div class="mb-2">
						<b class="font-bold">Custom</b> Business Credit
					</div>
				</div>

				<button
					type="button"
					class="btn btn-black xl:px-10"
					:disabled="plans.loading"
				>
					Contact Us
				</button>


			</div>
		</div>

		<div class="font-bold text-xl mb-3">Features</div>

		<div class="border border-blue-200 overflow-y-auto" style="max-height: 400px">
			<table class="table">
				<tbody>
					<tbody>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Real-time payment overview
							</td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Individual spending policies
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Controller spending dashboard
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Account balance alerts
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Card Spending Tracker
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Expense Approval
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Accounting support. Third-party Integration (Xero, QuickBooks,)
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Multi-level Approval
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Receipt Management
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Smart Receipt Scanner
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Automatic matching of payment categories and expenses
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Automatic receipt reminders
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Assisted onboarding & team training
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Dedicated support and account management
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						<tr>
							<td class="w-2/10 font-medium text-gray-600 text-base" style="font-size: 12px">
								Reconciliation Summary
							</td>
							<td class="w-2/10 text-center"><ion-icon name="close-outline" class="text-blue-500 text-xl opacity-50"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
							<td class="w-2/10 text-center"><ion-icon name="checkmark-outline" class="text-blue-500 text-xl"></ion-icon></td>
						</tr>
						
					</tbody>
				</tbody>
			</table>
			
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10" ref="confirmationModal">
			<template v-if="plans.selected">
				<div class="text-lg font-bold mb-6">
					Selected Plan: <span class="text-gray-700">{{ plans.selected.name | sentenceCase }}</span>
				</div>

				<template v-if="getFormError(subscription)">
					<div class="alert alert-red-soft mb-6">
						<div class="alert-icon">!</div>
						<span>{{ getFormError(subscription) }}</span>
					</div>
				</template>

				<template v-if="plans.selected.name == 'basic'">

					<div class="text-gray-600 mb-8">
						You can proceed without making a payment.
					</div>

					<div class="div">
						<button
							class="btn btn-red-outline mr-2"
							@click.prevent="$refs.confirmationModal.close"
							:disabled="subscription.loading"
						>
							Cancel
						</button>
						<button
							class="btn btn-blue"
							@click.prevent="subscribe"
							:disabled="subscription.loading"
						>
							<span v-if="subscription.loading">Verifying...</span>
							<span v-else>Continue</span>
						</button>
					</div>
				</template>

				<template v-else>
					<div class="text-lg font-bold mb-6">
						Billing Interval: <span class="text-gray-700">{{ billingOption | sentenceCase }}</span>
					</div>

					<div class="text-gray-600 mb-8">
						You will be prompted to make payment.
					</div>

					<div class="div">
						<button
							class="btn btn-red-outline mr-2"
							@click.prevent="$refs.confirmationModal.close"
							:disabled="subscription.loading"
						>
							Cancel
						</button>
						<paystack
							class="btn btn-blue"
							:amount="fee"
							:callback="subscribe"
							:disabled="subscription.loading"
						>
							<span v-if="subscription.loading">Verifying...</span>
							<span v-else>Pay ₦ {{ fee | currency }}</span>
						</paystack>
					</div>
				</template>

			</template>
		</modal>

        <modal className="w-full md:w-2/5 xl:w-3/10" ref="createCardModal">

            <h1 class="text-2xl font-bold mb-10">Register a new card</h1>

			<form @submit.prevent="submit">

				<template v-if="getFormError(form)">
					<div class="alert alert-red-soft mb-16">
						<div class="alert-icon">!</div>
						<span>{{ getFormError(form) }}</span>
					</div>
				</template>

				<div class="mb-10" v-if="!groupOptions.length && !groups.loading">
					<div class="alert alert-orange-soft mb-2">
						<span class="alert-icon">!</span>
						<span>No groups available</span>
					</div>
					<router-link :to="{ name: 'groups-new' }" class="text-sm text-gray-700">+ Create a new group</router-link>
				</div>
				<form-group
					type="select"
					:options="groupOptions"
					left-icon="people-circle-outline"
					name="group"
					:form="form"
					v-model="form.data.group.value"
					:loading="groups.loading"
				>
					Department
				</form-group>

				<div class="mb-10" v-if="!form.data.group.value">
					<div class="alert alert-gray-soft mb-2">
						<span class="alert-icon">!</span>
						<span class="text-gray-700">Select A Department</span>
					</div>
				</div>
				<div class="mb-10" v-else-if="!staffOptions.length && !staff.loading">
					<div class="alert alert-orange-soft mb-2">
						<span class="alert-icon">!</span>
						<span>No staff available in this department</span>
					</div>
					<router-link :to="{ name: 'staff-new' }" class="text-sm text-gray-700">+ Register a new staff</router-link>
				</div>
				<form-group
					type="select"
					:options="staffOptions"
					left-icon="person-outline"
					name="staff"
					:form="form"
					v-model="form.data.staff.value"
					:loading="staff.loading"
				>
					Staff
				</form-group>

				<div class="text-center">
					<button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
						<span v-if="form.loading">Loading</span>
						<span v-else>Submit</span>
					</button>
				</div>

			</form>

        </modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Your subscription was successful.
			</div>

			<router-link :to="{ name: 'dashboard' }" class="btn btn-blue">
				Go to dashboard
			</router-link>
		</modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				checkmark: require('@/assets/checkmark-base.svg'),

				billingOption: 'yearly',
				billingOptions: [
					{title: 'Bill Monthly', value: 'monthly'},
					{title: 'Bill Quarterly', value: 'quarterly'},
					{title: 'Bill Annually', value: 'yearly'},
					{title: 'Bill Biannually', value: 'biannually'},
				],
				features: this.$options.resource([]),
				plans: this.$options.resource([], { selected: null, active: null }),
				subscription: this.$options.resource(null),
				groups: this.$options.resource([]),
				staff: this.$options.resource([]),
				form: this.$options.basicForm([
					'group', 'staff', 'manager', {name: 'type', value: 'credit'}
				]),
			}
		},
		computed: {
			fee() {
				console.log(`${this.billingOption}_fee`);
				return this.plans.selected?.[`${this.billingOption}_fee`]
			},
			groupOptions() {
				return this.groups.data.map(group => ({ title: group.name, value: group.id }));
			},
			staffOptions() {
				return this.staff.data.filter(staff => staff.group_id == this.form.data.group.value).map(staff => ({ title: staff.name, value: staff.id }));
			},
		},
		beforeMount() {
			(async () => {
				await this.getPlans();
				this.getActivePlan();
			})();
			this.getFeatures();
			this.getGroups();
			this.getStaff();
		},
		mounted() {
			if (this.$route.query?.cardType?.match(/credit|debit/)) {
				this.form.data.type.value = this.$route.query.cardType
			}
		},
		watch: {
			'form.data.group.value'() {
				this.form.data.staff.value = '';
			},
			'form.data.type.value'() {
				this.form.data.manager.value = '';
			},
		},
		methods: {
			selectPlan(plan) {
				console.log(plan);
				this.plans.selected = plan;
				this.$refs.confirmationModal.open();
			},



			async getFeatures() {
				this.features.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/plan/features`,
					headers: this.headers,
					success: response => {
						this.features.data = response.data.data;
					},
					error: error => {
						this.features.error = error;
					}
				});
				this.features.loading = false;
			},
			async getPlans() {
				this.plans.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/plan`,
					headers: this.headers,
					success: response => {
						this.plans.data = response.data.data;
					},
					error: error => {
						this.plans.error = error;
					}
				});
				this.plans.loading = false;
			},
			async getActivePlan() {
				this.plans.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/plan/unique-plan/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.plans.active = response.data.data;
						this.plans.selected = this.plans.data.find(plan => plan.id === this.plans?.active?.company_plan_id);
					},
					error: error => {
						this.plans.error = error;
					}
				});
				this.plans.loading = false;
			},
			async subscribe(data) {
				if (data) {
					var { ref_no } = data;
				}else {
					ref_no = null;
				}

				this.subscription.loading = true;
				await this.$post({
					url: `${this.$baseurl}/companies/plan/subscribe`,
					data: {
						company_id: this.user.company_id,
						company_plan_id: this.plans.selected.id,
						duration_label: `${this.billingOption}_fee`,
						payment_method: this.plans.selected.name == 'basic' ? 'free' : this.plans.selected.name == 'custom' ? 'custom' : 'paystack',
						ref_no,
					},
					headers: this.headers,
					success: response => {
						// this.$refs.createCardModal.open();
						this.$refs.confirmationModal.close();
						this.$store.commit('session/free', state => {
							state.user = {
								...state.user,
								company: {
									...state.user.company,
									company_unique_plan: response.data.data
								}
							}
						});
						this.$refs.successModal.open();
						this.subscription.success = true;
					},
					error: error => {
						this.subscription.error = error;
					}
				});
				this.subscription.loading = false;
			},
			async submit() {
				const type = this.form.data.type.value;

				this.form.loading = true;
				await this.sendRequest(`corporate.cards.${type}.new`, {
					data: {
						companyId: this.user.company_id,
						groupId: this.form.data.group.value,
						employeeId: this.form.data.staff.value,
						planId: this.plans.selected.id
					},
					success: () => {
						this.$refs.successModal.open();
					},
					error: error => {
						this.form.error = error;
					}
				})
				this.form.loading = false;
			},
			async getGroups() {
				this.groups.loading = true;
				await this.sendRequest('corporate.groups.all', {
					success: (response) => {
						this.groups.data = response.data.groups;
					}
				})
				this.groups.loading = false;
			},
			async getStaff() {
				this.staff.loading = true;
				await this.sendRequest('corporate.staff.all', {
					success: response => {
						this.staff.data = response.data.data;
					}
				})
				this.staff.loading = false;
			},
		}
	}
</script>